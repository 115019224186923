import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { IReducer } from '../stores/IndexReducer';
import Logo from '../resources/logo/USSF-Primary.png';
import { Auth } from 'aws-amplify';
import { logoutAction } from '../stores/user/Actions';
import { IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { store } from '../stores/index';
import { checkAdminsGroup } from '../utils';

const Navbar = () => {
  const history = useHistory();
  const user = store.getState().userReducer.user?.email;
  const roles = store.getState().userReducer.user?.role || [];
  const isAdmin = checkAdminsGroup(roles);
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state: IReducer) => {
    return {
      isLogin: state.userReducer.user ? true : false,
      //   userRole: state.userReducer.user ? state.userReducer.user.role : [],
    };
  });

  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  const selectedStyle = { borderBottom: '2px solid black' };

  const handleLogout = async () => {
    await Auth.signOut();
    dispatch(logoutAction());
    history.push('/');
    setCurrentPath('/');
  };
  // console.log(currentPath);
  useEffect(() => {
    // console.log(history.location.pathname);
    setCurrentPath(history.location.pathname);
  }, [history.location.pathname]);
  return (
    <header className="flex justify-between items-center bg-white shadow-md px-3">
      <NavLink exact to="/" onClick={() => setCurrentPath('/')}>
        <img src={Logo} alt="US-Soccer Logo" className="w-14" />
      </NavLink>
      <div>
        {isLogin ? (
          <div className="flex items-center space-x-4">
            <Link
              onClick={() => setCurrentPath('/dashboard')}
              to="/dashboard"
              className="w-full focus:outline-none transition duration-300 ease-out flex items-center justify-center px-0 py-2 border border-transparent text-base font-medium rounded-md hover:bg-blue-200 ml-3"
            >
              <span style={currentPath === '/dashboard' || currentPath === '/' ? selectedStyle : {}}>DashBoard</span>
            </Link>
            {isAdmin && (
              <>
                <Link
                  onClick={() => setCurrentPath('/users')}
                  to="/users"
                  className="w-full focus:outline-none transition duration-300 ease-out flex items-center justify-center px-0 py-2 border border-transparent text-base font-medium rounded-md hover:bg-blue-200 ml-3"
                >
                  <span style={currentPath === '/users' ? selectedStyle : {}}>Users</span>
                </Link>
              </>
            )}
            <Link
              onClick={() => setCurrentPath('/doc')}
              to="/doc"
              className="w-full focus:outline-none transition duration-300 ease-out flex items-center justify-center px-0 py-2 border border-transparent text-base font-medium rounded-md hover:bg-blue-200 ml-3"
            >
              <span style={currentPath === '/doc' ? selectedStyle : {}}>Docs</span>
            </Link>
            <Link
              onClick={() => setCurrentPath('/dashboard')}
              to="/dashboard"
              className="w-full focus:outline-none transition duration-300 ease-out flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-blue-900 bg-blue-100 hover:bg-blue-200 ml-3"
            >
              {user}
            </Link>
            <IconButton onClick={handleLogout}>
              <ExitToAppIcon color="secondary" />
            </IconButton>
          </div>
        ) : (
          <>
            <Link
              onClick={() => setCurrentPath('/doc')}
              to="/doc"
              className="w-14 focus:outline-none transition duration-300 ease-out flex items-center justify-center mx-4 px-0 py-2 border border-transparent text-base font-medium rounded-md bg-blue-200 ml-3 hover:bg-blue-300 ml-3"
            >
              <span>Docs</span>
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Navbar;
