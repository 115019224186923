import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import VIcon from '../components/icons';
import {
  emailRegex,
  lowercaseRegex,
  uppercaseRegex,
  numericRegex,
  specialCharRegex,
  setAccessToken,
  checkUsersGroup,
} from '../utils';
import { useDispatch } from 'react-redux';
import { loginAction } from '../stores/user/Actions';
import { Auth } from 'aws-amplify';
import { Toaster } from '../components/Toaster';
import { VaccineBackdrop } from '../components/Backdrop/index';

interface IData {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = await Auth.signIn(data.email, data.password);
      const jwt_token = res.signInUserSession.accessToken.jwtToken;
      const role = res.signInUserSession.idToken.payload['cognito:groups'] || [];
      var isUser = checkUsersGroup(role);
      if (!isUser) {
        await Auth.signOut();
        // dispatch(logoutAction());
        Toaster('User not confirmed. Contact with administrator', 'danger');
        history.push('/');
        return;
      }
      setAccessToken(jwt_token);

      dispatch(
        loginAction({
          email: res.attributes.email,
          username: res.username,
          sub: res.attributes.sub,
          role: res.signInUserSession.idToken.payload['cognito:groups'] || [],
        }),
      );
      history.push('/dashboard');
    } catch (e) {
      Toaster(e.message, 'danger');
    }
  });

  return (
    <div className=" flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <VaccineBackdrop open={isSubmitting}></VaccineBackdrop>
      <div className="max-w-md w-full">
        <div className="bg-white shadow-sm border-2 border-blue-900 rounded-xl px-6 py-20 max-w-sm">
          <div>
            <h2 className="mb-6 text-center text-3xl font-extrabold text-gray-900">Log in</h2>
          </div>

          <form className="mt-8 space-y-6" onSubmit={onSubmit}>
            <div className="rounded-md shadow-sm space-y-8">
              <div>
                <TextField
                  type="text"
                  name="email"
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  inputRef={register}
                  error={!!errors.email}
                />
                {errors.email && <p className="text-red-500 text-xs italic mt-1">{errors.email.message}</p>}
              </div>
              <div>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  fullWidth
                  variant="outlined"
                  placeholder="Password"
                  inputRef={register}
                  error={!!errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-visible" />
                          ) : (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-hidden" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && <p className="text-red-500 text-xs italic mt-1">{errors.password.message}</p>}
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <Link to="/forgot-password" className="text-blue-800 hover:text-blue-900">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full py-4 px-4 border border-transparent text-sm rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Log in
              </button>
            </div>
            <div className="flex items-center justify-center">
              <div className="text-sm">
                <p>Don't have an account?</p>
                <span>
                  <Link to="/sign-up" className="text-blue-800 hover:text-blue-900">
                    Click here to Sign Up
                  </Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
