import * as Yup from 'yup';
import { store } from '../stores';

// Auth checker
export const isAuthenticate = () => (store.getState().userReducer.user ? true : false);

// export const isAuthenticate = () => true;

// all regex
export const emailRegex = /^([a-z\d._-]+)@([a-z\d_-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*[0-9])/;
export const specialCharRegex = /(?=.*[\^$*.[\]{}()?“!@#%&/,><’:;|_~`/-])/;
export const durationRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;

export const validationSchema = Yup.object().shape({
  // facility: Yup.string().trim().required('Required'),
  // lName: Yup.string().trim().required('Required'),
  // fName: Yup.string().trim().required('Required'),
  // mName: Yup.string().trim().required('Required'),
  // city: Yup.string().trim().required('Required'),
  // state: Yup.string().required('Required'),
  // zip: Yup.string().trim().required('Required'),
  // apt: Yup.string().trim().required('Required'),
  // sex: Yup.string().required('Required'),
  // patientPhoneNumber: Yup.string().trim().required('Required'),
  // address: Yup.string().trim().required('Required'),
  // legalLastName: Yup.string().trim().required('Required'),
  // legalFirstName: Yup.string().trim().required('Required'),
  // legalMiddleName: Yup.string().trim().required('Required'),
  // race: Yup.string().required('Required'),
  // ethnicity: Yup.string().required('Required'),
  // vaccineDoseCount: Yup.string().required('Required'),
});

export interface IQuestions {
  id: string;
  question: string;
}
export interface IConsentData {
  text: string;
}

export const uiLanguageList = {
  English: {
    ChooseLanguage: 'Choose language',
    Title: 'COVID-19 VACCINE SCREENING AND CONSENT FORM',
    facility: 'Administration Facility Name/Facility ID:',

    section1: 'SECTION 1: INFORMATION ABOUT PATIENT (PLEASE PRINT)',
    consent: 'Consent Statements From Patient',

    name: 'Name',
    fName: 'First Name',
    lName: 'Last Name',
    mName: 'Middle Initial',
    dob: 'Birthday',
    pContact: 'Contact Number (Patient/Guardian)',
    address: 'Address :',
    apt: 'Apt/Room#',
    city: 'City',
    state: 'State',
    zip: 'Zip',

    nameLegalGuardian: 'Name of Legal Guardian:',
    legalLastName: 'Last Name',
    legalFirstName: 'First Name',
    legalMiddleName: 'Middle Initial',

    sex: 'Sex',
    male: 'Male',
    female: 'Female',
    unknown: 'Unknown',
    insure: 'Do you have Insurance?',

    Race: 'Race',
    race1: 'American Indian or Alaska Native',
    race2: 'Asian Indian',
    race3: 'Black or African American',
    race4: 'Native Hawaiian or other',
    race5: 'GUAMANIAN/CHARMORRO',
    race6: 'White',
    race7: 'Other Asian',
    race8: 'Other Nonwhite',
    race9: 'Other Pacific Islander',
    race10: 'Unknown',
    race11: 'Vietnamese',
    race12: 'Chinese',
    race13: 'Filipino',
    race14: 'Japanese',
    race15: 'Korean',
    race16: 'Samoan',

    ethnicity: 'Ethnicity',
    ethnicity1: 'Hispanic or Latino',
    ethnicity2: 'Not Hispanic or Latino',
    ethnicity3: 'Unknown',

    pIC: 'Primary Insurance Carrier',
    pICid: 'ID',
    pICgrp: 'Grp',
    pICinfo: 'Insurance Company',
    pICphone: 'Insurance Company Phone',
    pICname: 'Insured’s Name',
    pRelationship: 'Relationship',
    pDob: 'Insured’s Date of Birth',

    sIC: 'Secondary Insurance Carrier',
    sICid: 'ID',
    sICgrp: 'Grp',
    sICinfo: 'Insurance Company',
    sICphone: 'Insurance Company Phone',
    sICname: 'Insured’s Name',
    sRelationship: 'Relationship',
    sDob: 'Insured’s Date of Birth',

    vaccineDoseCheck: 'Is this the patient’s first or second dose of the COVID-19 vaccination?',
    fDose: 'First Dose',
    sDose: 'Second Dose',

    section2: 'SECTION 2: COVID-19 SCREENING QUESTIONS',
    checkYesNo: 'Please check YES or No for each question.',
    sec2row1:
      '1. Do you have today or have you had at any time in the last 10 days a fever, chills, cough, shortness of breath, difficulty breathing, fatigue, muscle or body aches, headache, new loss of taste or smell, sore throat, congestion or runny nose,nausea, vomiting, or diarrhea?',
    sec2row2: '2. Have you tested positive for and/or been diagnosed with COVID-19 infection within the last 10 days?',
    sec2row3:
      '3. Have you had a severe allergic reaction (e.g. needed epinephrine or hospital care) to a previous dose of this vaccine or to any of the ingredients of this vaccine?',
    sec2row4: '4. Have you had any other vaccinations in the last 14 days (e.g. influenza vaccine, etc.)?',
    sec2row5:
      '5. Have you had any COVID-19 Antibody therapy within the last 90 days (e.g. Regeneron, Bamlanivimab, COVID Convalescent Plasma, etc.)?',

    section3: 'SECTION 3: IMMUNIZATION SCREENING GUIDANCE FOR COVID-19 VACCINE',
    sec3row1:
      '1. Do you carry an Epi-pen for emergency treatment of anaphylaxis and/or have allergies or reactions to any medications,foods, vaccines or latex?',
    sec3row2: '2. For women, are you pregnant or is there a chance you could become pregnant?',
    sec3row3: '3. For women, are you currently breastfeeding?',
    sec3row4: '4. Are you immunocompromised or on a medication that affects your immune system?',
    sec3row5: '5. Do you have a bleeding disorder or are you on a blood thinner/blood-thinning medication?',
    sec3row6:
      '6. Have you received a previous dose of any COVID-19 vaccine? If yes, which manufacturer’s vaccine did you receive?',

    prevVaccineManufacturer: 'Previous Covid-19 vaccine manufacturer name',

    yes: 'Yes',
    no: 'No',

    cr1:
      'I certify that I am: (a) the patient and at least 16 years of age; (b) the legal guardian of the patient and confirm that the patient is at least 16 years of age; or (c) legally authorized to consent for vaccination for the patient named above. Further, I hereby give my consent to the Florida Department of Health (DOH) or its agents to administer the COVID-19 vaccine.',
    cr2:
      'I understand that this product has not been approved or licensed by FDA, but has been authorized for emergency use by FDA, under an EUA to prevent Coronavirus Disease 2019 (COVID-19) for use in individuals either 16 years of age or older or 18 years of age and older; and the emergency use of this product is only authorized for the duration of the declaration that circumstances exist justifying the authorization of emergency use of the medical product under Section 564(b)(1) of the FD&C Act unless the declaration is terminated or authorization revoked sooner.',
    cr3:
      'I understand that it is not possible to predict all possible side effects or complications associated with receiving vaccine(s). I understand the risks and benefits associated with the above vaccine and have received, read and/or had explained to me the Emergency Use Authorization Fact Sheet on the COVID-19 vaccine I have elected to receive. I also acknowledge that I have had a chance to ask questions and that such questions were answered to my satisfaction.',
    cr4:
      'I acknowledge that I have been advised to remain near the vaccination location for approximately 15 minutes (or more in specific cases) after administration for observation. If I experience a severe reaction, I will call 9-1-1 or go to the nearest hospital.',
    cr5:
      'On behalf of myself, my heirs and personal representatives, I hereby release and hold harmless the State of Florida, the Florida Department of Health (DOH), the Florida Division of Emergency Management (FDEM) and their staff, agents, successors, divisions, affiliates, subsidiaries, officers, directors, contractors and employees from any and all liabilities or claims whether known or unknown arising out of, in connection with, or in any way related to the administration of the vaccine listed above.',
    cr6:
      'I acknowledge that: (a) I understand the purposes/benefits of Florida SHOTS, Florida’s immunization registry and (b) DOH will include my personal immunization information in Florida SHOTS and my personal immunization information will be shared with the Centers for Disease Control (CDC) or other federal agencies.',
    cr7:
      'I further authorize DOH, FDEM, or its agents to submit a claim to my insurance provider or Medicare Part B without supplemental coverage payment for me for the above requested items and services. I assign and request payment of authorized benefits be made on my behalf to DOH, FDEM, or its agents with respect to the above requested items and services. I understand that any payment for which I am financially responsible is due at the time of service or if DOH invoices me after the time of service, upon receipt of such invoice.',
    cr8: 'I acknowledge receipt of the DOH Notice of Privacy Practices.',

    pSignature: 'Signature of Patient or Authorized Representative',
    date: 'Date',
    representativeInfo: 'Print Name of Representative and Relationship to Person Receiving Vaccine:',
    site: 'Site(LD/RD)',
    route: 'Route',
    manufacturer: 'Manufacturer(MVX)',
    lot: 'Lot # Unit of Use/Unit of Sale',
    expDate: 'Expiration Date',
    euaDate: 'Date of EUA Fact Sheet',

    facilityInfo: 'Administered at location: facility name/ID',
    facilityType: 'Administered at location: Type',
    adminAdress: 'Administration Address',
    cvx: 'CVX(product',
    organization: 'Sending Organization',

    vaccinatorName: 'Vaccinator Print Name',
    sign: 'Signature',
    ASignature: 'Signature of authorized vaccinator',
    suffix: 'Vaccine administering provider suffix:',

    lfront: 'Upload drivers license front part',
    lback: 'Upload drivers license back part',
    ifront: 'Upload insurance card front part',
    iback: 'Upload insurance card back part',
    submit: 'Submit',
    reset: 'Reset',
    personal_details: 'PERSONAL DETAILS',
  },
  Spanish: {
    ChooseLanguage: 'Choose language',
    Title: 'FORMULARIO DE SELECCIÓN Y CONSENTIMIENTO PARA LA VACUNA PARA LA COVID-19',
    facility: 'Nombre del centro de administración/N.º de identificación del centro:',
    consent: 'Declaraciones de consentimiento del paciente',
    ASignature: 'Firma del vacunador autorizado',
    insure: '¿Tiene usted seguro?',

    section1: 'SECCIÓN 1: INFORMACIÓN SOBRE EL PACIENTE (ESCRIBA EN LETRA DE IMPRENTA)',

    name: 'Nombre',
    fName: 'Primer nombre',
    lName: 'Apellido',
    mName: 'Inicial del 2.º nombre',
    dob: 'Fecha de nacimiento',
    pContact: 'N.º de teléfono (paciente o tutor)',
    address: 'Dirección',
    apt: 'N.º de apartamento/habitación',
    city: 'Ciudad',
    state: 'Estado',
    zip: 'Código postal',

    nameLegalGuardian: 'Nombre del tutor legal:',
    legalLastName: ' Apellido',
    legalFirstName: 'Primer nombre',
    legalMiddleName: 'Inicial del 2.º nombre',

    sex: 'Sexo',
    male: 'Mujer',
    female: 'Hombre',
    unknown: 'Desconocido',

    Race: 'Raza',
    race1: 'Indio americano/Nativo de Alaska',
    race2: 'Asiático',
    race3: 'Negro o afroamericano',
    race4: 'Nativo hawaiano u otro',
    race5: 'GUAMÁN/CHARMORRO',
    race6: 'Blanco',
    race7: 'Otro asiático',
    race8: 'Otro no blanco',
    race9: 'Otro isleño del Pacífico',
    race10: 'Desconocido',
    race11: 'vietnamita',
    race12: 'China/Chino',
    race13: 'Filipino/Filipina',
    race14: 'Japonés/japonesa',
    race15: 'Coreana/Coreano',
    race16: 'Samoana/Samoano',

    ethnicity: 'Origen étnico',
    ethnicity1: 'Hispano o latino',
    ethnicity2: 'No hispano ni latino',
    ethnicity3: 'Desconocido',

    pIC: 'Compañía de seguros primaria',
    pICid: 'IDENTIFICACIÓN',
    pICgrp: 'N.º de lote',
    pICinfo: 'Compañía de seguros',
    pICphone: 'N.º de teléfono de la compañía de seguros',
    pICname: 'Nombre del asegurado',
    pRelationship: 'Relación',
    pDob: 'Fecha de nacimiento del asegurado',

    sIC: 'Compañía de seguros secundaria',
    sICid: 'IDENTIFICACIÓN',
    sICgrp: 'N.º de lote',
    sICinfo: 'Compañía de seguros',
    sICphone: 'N.º de teléfono de la compañía de seguros',
    sICname: 'Nombre del asegurado',
    sRelationship: 'Relación',
    sDob: 'Fecha de nacimiento del asegurado',

    vaccineDoseCheck: '¿Es esta la primera o segunda dosis de la vacuna para la COVID-19 del paciente?',
    fDose: 'Primera dosis',
    sDose: 'Segunda dosis',

    section2: 'SECCIÓN 2: PREGUNTAS DE DETECCIÓN DE LA COVID-19',
    checkYesNo: 'Marque SÍ o No para cada pregunta',
    sec2row1:
      '1. ¿Tiene hoy o ha tenido en algún momento de los últimos 10 días fiebre, escalofríos, tos, falta de aliento, dificultad para respirar, fatiga, dolores musculares o corporales, dolor de cabeza, pérdida repentina del sentido del olfato o del gusto, dolor de garganta, congestión (nariz tapada) o secreción nasal (moqueo), náuseas, vómitos o diarrea?',
    sec2row2:
      '2. ¿Ha tenido un resultado positivo en una prueba de detección de infección con la COVID-19 o se la han diagnosticado esta en los últimos 10 días?',
    sec2row3:
      '3. ¿Ha tenido una reacción alérgica grave (p. ej., necesitó epinefrina o atención en un hospital) a una dosis previa de esta vacuna o a alguno de los ingredientes de esta vacuna?',
    sec2row4: '4. ¿Ha recibido alguna otra vacuna en los últimos 14 días (p. ej., vacuna contra la influenza, etc.)?',
    sec2row5:
      '5. ¿Ha recibido alguna terapia de anticuerpos contra la COVID-19 en los últimos 90 días (p. ej., Regeneron, Bamlanivimab,plasma de convalecientes de COVID, etc.?',

    section3: 'SECCIÓN 3: GUÍA DE SELECCIÓN PARA LA INMUNIZACIÓN CON LA VACUNA PARA LA COVID-19',
    sec3row1:
      '1. ¿Lleva con usted un Epi-pen para el tratamiento de emergencia de la anafilaxia y/o tiene alergias o reacciones a algún medicamento, alimento, vacuna o al látex?',
    sec3row2: '2. En el caso de las mujeres, ¿está embarazada o existe la posibilidad de que quede embarazada?',
    sec3row3: '3. En el caso de las mujeres, ¿está amamantando actualmente?',
    sec3row4: '4. ¿Está inmunodeprimido/a o está recibiendo un medicamento que afecta al sistema inmunitario?',
    sec3row5: '5. ¿Tiene un trastorno hemorrágico o está tomando un anticoagulante?',
    sec3row6:
      '6. ¿Ha recibido una dosis previa de alguna vacuna para la COVID-19? En caso afirmativo, ¿de qué fabricante era la vacuna que recibió?',

    yes: 'Sí',
    no: 'No',
    prevVaccineManufacturer: 'Nombre anterior del fabricante de la vacuna Covid-19',

    cr1:
      'Certifico que: (a) soy el paciente y tengo al menos 16 años de edad; (b) soy el tutor legal del paciente y confirmo que el paciente tiene al menos 16 años de edad; o (c) estoy legalmente autorizado para otorgar el consentimiento para la vacunación del paciente mencionado anteriormente. Además, otorgo mi consentimiento para que el Departamento de Salud de Florida (Florida Department of Health, DOH) o sus agentes administren la vacuna para la COVID-19.',
    cr2:
      'Entiendo que este medicamento no ha sido aprobado ni autorizado por la FDA, pero ha sido autorizado por la FDA para su uso de emergencia,en virtud de una Autorización para uso de emergencia (Emergency Use Authorization, EUA) para prevenir la enfermedad por el coronavirus 2019 (COVID-19), para su uso en personas de 16 años de edad o mayores, o bien, de 18 años de edad o mayores; y el uso de emergencia de este medicamento solo está autorizado durante la vigencia de la declaración de que existen circunstancias que justifican la autorización de dicho uso de urgencia del medicamento en virtud de la Sección 564(b)(1) de la Ley Federal de Alimentos, Medicamentos y Cosméticos (Food,Drug, and Cosmetic Act, FD&C Act), a menos que se termine la declaración o se revoque antes.',
    cr3:
      'Entiendo que no es posible predecir todos los posibles efectos secundarios o complicaciones asociadas a la administración de vacunas.Entiendo los riesgos y beneficios asociados a la vacuna mencionada anteriormente y he recibido, leído y/o me han explicado la Hoja informativa de uso de emergencia sobre la vacuna para la COVID-19 que he elegido recibir. Reconozco que he tenido la oportunidad de hacer preguntas y que me respondieron dichas preguntas de forma satisfactoria.',
    cr4:
      'Reconozco que se me ha aconsejado que permanezca cerca del centro de la vacunación durante aproximadamente 15 minutos (o más, en casos específicos) para estar en observación después de la administración de la vacuna. Si experimento una reacción grave, llamaré al 9-1-1 o iré al hospital más cercano.',
    cr5:
      'En mi nombre, en el de mis herederos y representantes personales, por la presente libero y eximo de responsabilidad al Estado de Florida, al Departamento de Salud (Department of Health, DOH) de Florida, a la División de Manejo de Emergencias de Florida (Florida Division of Emergency Management, FDEM) y a su personal, agentes, sucesores, divisiones, filiales, subsidiarias, funcionarios, directores, contratistas y empleados de cualquier responsabilidad o reclamación, ya sea conocida o desconocida, que surja de la administración de la vacuna mencionada anteriormente, o que esté relacionada con ella de cualquier manera.',
    cr6:
      'Doy fe de que: (a) entiendo los propósitos/beneficios de Florida SHOTS, el registro de vacunación de Florida; y que (b) el DOH incluirá mi información personal de vacunación en Florida SHOTS y que esta será compartida con los Centros para el Control de Enfermedades (Centers for Disease Control, CDC) u otras agencias federales.',
    cr7:
      'Además, autorizo al DOH, a la FDEM o a sus agentes a presentar una reclamación a mi proveedor de seguros o a la Parte B de Medicare sin que se me pague una cobertura suplementaria por los artículos y servicios solicitados anteriormente. Asigno y solicito que el pago de los beneficios autorizados se haga en mi nombre al DOH, la FDEM o a sus agentes con respecto a los artículos y servicios solicitados anteriormente. Entiendo que cualquier pago del cual soy financieramente responsable se deberá realizar al momento del servicio, o si el DOH me factura después del momento del servicio, al recibir dicha factura.',
    cr8: 'Confirmo la recepción del Aviso de Prácticas de Privacidad del DOH.',

    pSignature: 'Firma del paciente o representante autorizado',
    date: 'Fecha',
    representativeInfo: 'Nombre en letra de imprenta del representante y relación con la persona que recibe la vacuna:',
    site: 'Centro(LD/RD)',
    route: 'Vía',
    manufacturer: 'Fabricante (MVX)',
    lot: 'N.º de lote Unidad de uso/ Unidad de ventas',
    expDate: 'Fecha de vencimiento',
    euaDate: 'Fecha de la Hoja informativa de la EUA',

    facilityInfo: 'Se administra en la ubicación: nombre/ID de la instalación',
    facilityType: 'Ubicación donde se administra: Tipo',
    adminAdress: 'Dirección donde se administra',
    cvx: 'CVX (producto)',
    organization: 'Organización que envía:',

    vaccinatorName: 'Nombre de la persona que administra la vacuna, en letra de imprenta:',
    sign: 'Firma',
    suffix: 'Sufijo del proveedor que administra la vacuna',

    lfront: 'Capturar la parte frontal de la licencia de conducir',
    lback: 'Captura la parte posterior de la licencia de conducir',
    ifront: 'Capturar la parte frontal de la tarjeta de seguro',
    iback: 'Captura la parte posterior de la tarjeta de seguro',
    submit: 'Enviar',
    reset: 'Reiniciart',
    personal_details: 'DETALLES PERSONALES',
  },
  Creole: {
    ChooseLanguage: 'Choose language',
    Title: 'FÒM KONSANTMAN AK DEPISTAJ VAKSEN COVID-19',
    facility: 'Non Etablisman Administrasyon an/ID Etablisman an:',
    personal_details: 'DETAY PÈSONÈL',
    ASignature: 'Siyati vaksen ki otorize',
    section1: 'SEKSYON 1: ENFÒMASYON SOU PASYAN AN (TANPRI EKRI AK LÈT DETACHE)',
    consent: 'Deklarasyon Konsantman Soti nan Pasyan an',

    insure: 'Èske ou gen Asirans?',

    name: 'Non',
    fName: 'Prenon',
    lName: 'Siyati',
    mName: 'Inisyal Dezyèm Prenon',
    dob: 'Inisyal Dezyèm Prenon',
    pContact: 'Nimewo Telefòn Mobil (Pasyan oswa Responsab Legal)',
    address: 'Adrès',
    apt: 'Nimewo Apt/Chanm',
    city: 'Vil',
    state: 'Eta',
    zip: 'Kòd Postal',

    nameLegalGuardian: 'Non Responsab Legal:',
    legalLastName: 'Siyati',
    legalFirstName: 'Prenon',
    legalMiddleName: 'Inisyal Dezyèm Non',

    sex: 'Sèks',
    male: 'Fi',
    female: 'Gason',
    unknown: 'Enkoni',

    Race: 'Ras',
    race1: 'Endyen Ameriken oswa Natifnatal Alaska',
    race2: 'Azyatik',
    race3: 'Nwa oswa Afriken Ameriken',
    race4: 'Natifnatal Awayi oswa lòt',
    race5: 'GUAMANIAN/CHARMORRO',
    race6: 'Blan',
    race7: 'Lòt Azyatik',
    race8: 'Lòt Moun ki pa blan',
    race9: 'Lòt Natifnatal Zile Pasifik',
    race10: 'Enkoni',
    race11: 'Vyetnamyen',
    race12: 'Chinwa',
    race13: 'Filipino',
    race14: 'Japonè',
    race15: 'Koreyen',
    race16: 'Samoan',

    ethnicity: 'Gwoup Etnik',
    ethnicity1: 'Ispanik oswa Latino',
    ethnicity2: 'Pa Ispanik oswa Latino',
    ethnicity3: 'Enkon',

    pIC: 'Konpayi Asirans Prensipal',
    pICid: 'Nimewo ID',
    pICgrp: 'Nimewo Gwoup',
    pICinfo: 'Konpayi Asirans',
    pICphone: 'Nimewo Telefòn Konpayi Asirans',
    pICname: 'Non Moun ki Asire a',
    pRelationship: 'Relasyon',
    pDob: 'Dat Nesans Moun ki Asire a',

    sIC: 'Konpayi Asirans Segondè',
    sICid: 'Nimewo ID',
    sICgrp: 'Nimewo Gwoup',
    sICinfo: 'Konpayi Asirans',
    sICphone: 'Nimewo Telefòn Konpayi Asirans',
    sICname: 'Non Moun ki Asire a',
    sRelationship: 'Relasyon',
    sDob: 'Dat Nesans Moun ki Asire a',

    vaccineDoseCheck: 'Èske se premye oswa dezyèm dòz vaksinasyon COVID-19 pasyan an?',
    fDose: 'Premye Dòz',
    sDose: 'Dezyèm Dòz',

    section2: 'SEKSYON 2: KESYON DEPISTAJ COVID-19',
    checkYesNo: 'Tanpri, asire ou tcheke WI oswa Non pou chak kesyon.',
    sec2row1:
      '1. Èske ou gen jodi a, oswa èske ou te gen nenpòt lè nan 10 jou ki sot pase yo, lafyèv, frison, tous, souf kout, difikilte pou respire, fatig, doulè nan misk oswa doulè nan kò, maltèt, vin pa ka pran gou oswa pran sant, malgòj, konjesyon oswa nen k ap koule, kèplen oswa vomisman, oswa dyare?',
    sec2row2:
      '2. Èske ou te gen tès pozitif epi/oswa èske yo te fè yon dyagnostik enfeksyon COVID-19 pou ou nan 10 jou ki sot apse yo?',
    sec2row3:
      '3. Èske ou te gen yon reyaksyon alèjik grav (pa egzanp epinephrine oswa swen lopital te nesesè) ak yon dòz nan vaksen sa aanvan sa oswa ak nenpòt engredyan ki nan vaksen sa a?',
    sec2row4:
      '4. Èske ou te pran nenpòt lòt vaksinasyon nan 14 jou ki sot pase yo (pa egzanp, vaksen kont grip, elatriye)?',
    sec2row5:
      '5. Èske ou te resevwa nenpòt tretman ak Antikò COVID-19 nan 90 jou ki sot pase yo (pa egzanp, Regeneron, Bamlanivimab,Plasma Konvalesan COVID, elatriye)',

    section3: 'SEKSYON 3: GID DEPISTAJ VAKSINASYON POU VAKSEN COVID-19',
    sec3row1:
      '1. Èske ou pote yon Epi-pen pou tretman dijans anafilaksi epi/oswa èske ou fè alèji oswa reyaksyon ak nenpòt medikaman,manje, vaksen, oswa latèks?',
    sec3row2: '2. Pou fanm yo, èske ou ansent oswa èske gen yon chans pou ou ta vin ansent?',
    sec3row3: '3. Pou fanm yo, èske w ap bay tete kounye a?',
    sec3row4: '4. Èske ou gen defisyans sistèm iminitè oswa ou sou yon medikaman ki afekte sistèm iminitè ou?',
    sec3row5: '5. Èske ou gen yon maladi senyen oswa ou sou yon medikaman pou delye san/medikaman antikowagilan?',
    sec3row6:
      '6. Èske ou te resevwa yon dòz anvan sa nan nenpòt vaksen COVID-19? Si se wi, vaksen ki fabrikan ou te resevwa:',

    yes: 'Wi',
    no: 'Non',
    prevVaccineManufacturer: 'Non fabrikan vaksen Covid-19 anvan an',

    cr1:
      'Mwen sètifye mwen: (a) se pasyan an epi mwen gen laj omwen 16 lane; (b) se responsab legal pasyan an epi mwen konfime ke pasyan an gen laj omwen 16 lane; oswa (c) otorize legalman pou bay konsantman pou vaksinasyon pou pasyan ki endike anwo a. Anplis, ak dokiman sa,mwen bay Depatman Sante Florid (Department Of Health, DOH) oswa ajan li yo konsantman m pou bay vaksen COVID-19 la',
    cr2:
      'Mwen konprann Ajans Etazini pou Kontwòl Manje ak Medikaman (Food and Drug Administration, FDA) pa te apwouve oswa otorize pwodui sa a, men FDA te otorize li pou itilizasyon dijans, dapre yon Otorizasyon pou Itilizasyon Dijans (Emergency Use Authorization, EUA) pou anpeche Maladi Kowonaviris 2019 (COVID-19) pou itilizasyon kay moun ki gen swa laj 16 lane oswa plis oswa laj 18 lane oswa plis; epi itilizasyon dijans pwodui sa a otorize sèlman pandan dire deklarasyon egzistans sikonstans ki jistifye otorizasyon itilizasyon dijans pwodui medikal la dapre Seksyon 564(b)(1) nan Lwa Federal sou Manje, Medikaman ak Kosmetik (Federal Food, Drug, and Cosmetic Act, FD&C Act) sòf si deklarasyon an sispann oswa otorizasyon anile pi bonè.',
    cr3:
      'Mwen konprann li pa posib pou predi tout efè segondè oswa konplikasyon ki posib ki asosye ak lè moun resevwa vaksen an (yo). Mwen konprann risk ak avantaj ki asosye avèk vaksen ki anwo a, epi mwen te resevwa, mwen te li epi/oswa yo te eksplike m Fich Enfòmasyon Otorizasyon pou Itilizasyon Dijans vaksen COVID-19 mwen chwazi pou resevwa a. Mwen rekonèt tou ke mwen te gen okazyon pou poze kesyon e ke yo te reponn kesyon sa yo nan fason ki satisfè m',
    cr4:
      'Mwen rekonèt yo te konseye pou m rete toupre anplasman vaksinasyon an pou obsèvasyon pandan apeprè 15 minit (oswa pi lontan nan ka byen presi) apre yo fin bay vaksen an. Si mwen gen yon reyaksyon grav, m ap rele 9-1-1 oswa mwen prale nan lopital ki pi pre a.',
    cr5:
      'Nan non pa mwen, nan non eritye ak reprezantan pèsonèl mwen yo, ak dokiman sa, mwen retire responsabilite sou Eta Florid, Depatman Sante Florid (DOH), Divizyon Jesyon Ijans Florida (FDEM) ak anplwaye, ajan, siksesè, divizyon, afilye, filyal, dirijan, direktè, kontraktè ak anplwaye pa yo, tout responsablite oswa reklamasyon sanzesepsyon keseswa koni oswa enkoni ki vin genyen, ki gen koneksyon, oswa ki gen rapò nan nenpòt fason ak vaksen yo bay ki endike anwo a.',
    cr6:
      'Mwen rekonèt ke: (a) mwen konprann objektif/avantaj Florida SHOTS, rejis vaksinasyon Florid la epi (b) DOH pral mete enfòmasyon vaksinasyon pèsonèl mwen nan Florida SHOTS, epi y ap kominike enfòmasyon vaksinasyon pèsonèl mwen bay Sant pou Kontwòl ak Prevansyon Maladi (Centers for Disease Control and Prevention, CDC) oswa lòt ajans federal yo',
    cr7:
      'Anplis, mwen otorize DOH, FDEM, oswa ajan li yo soumèt yon reklamasyon bay founisè asirans mwen oswa bay Medicare Pati B san peman kouvèti asirans siplemantè pou mwen pou atik ak sèvis yo ki mande anwo. Mwen transfere epi mande pou fè peman avantaj otorize yo nan non mwen bay DOH, FDEM, oswa ajan li yo konsènan atik ak sèvis yo ki mande anwo a. Mwen konprann ke nenpòt peman mwen responsab pou li finansyèman dwe fèt nan moman sèvis la oswa si DOH faktire m apre moman sèvis la, peman an dwe fèt lè mwen resevwa bòdwo sa.',
    cr8: 'Mwen rekonèt mwen resevwa Avi sou Pratik Konfidansyalite DOH yo',

    pSignature: 'Siyati Pasyan an oswa Reprezantan Otorize a',
    date: 'Dat',
    representativeInfo: 'Ekri ak lèt detache Non Reprezantan an ak Kisa li ye pou Moun k ap Pran Vaksen an:',
    site: 'Sit(LD/RD)',
    route: 'Wout',
    manufacturer: 'Fabrikan (MVX)',
    lot: 'Nimewo Lo Inite Itilizasyon/Inite Lavant',
    expDate: 'Dat Ekspirasyon',
    euaDate: 'Dat Fich Enfòmasyon EUA',

    facilityInfo: 'Administre nan anplasman:Non/ID etablisman an',
    facilityType: 'Administre nan anplasman: Ki kalite',
    adminAdress: 'Adrès Administrasyon',
    cvx: 'CVX (pwodui)',
    organization: 'Òganizasyon k ap voye a',

    vaccinatorName: 'Ekri ak lèt detache Non Vaksinatè a',
    sign: 'Siyati',
    suffix: 'Sifiks founisè k ap administre Vaksen an',

    lfront: 'Kaptire lisans chofè pati devan an',
    lback: 'Capture lisans chofè pati tounen',
    ifront: 'Kaptire kat asirans pati devan an',
    iback: 'Capture kat asirans pati tounen',
    submit: 'Soumèt',
    reset: 'Reyajiste',
  },
};

export const generateQueryString = <T>(data: T) => {
  let queryString = '';
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key as keyof T];
      if (value || typeof value === 'number') {
        if (queryString.length === 0) {
          queryString += `${key}=${value}`;
        } else {
          queryString += `&${key}=${value}`;
        }
      }
    }
  }
  return queryString;
};

// access token
export const setAccessToken = (token: string) => {
  window.localStorage.setItem('access-token', token);
};
export const removeAccessToken = () => {
  window.localStorage.removeItem('access-token');
};
export const getAccessToken = (): string | null => {
  let accessToken: string | null = null;
  if (typeof window !== undefined && window.localStorage.getItem('access-token')) {
    accessToken = window.localStorage.getItem('access-token');
    return accessToken;
  } else {
    removeAccessToken();
  }
  return accessToken;
};

//check group
export const checkUsersGroup = (roles: Array<String>) => {
  var isUser = false;
  roles.forEach((item: String) => {
    if (item === 'dev-users') {
      isUser = true;
    } else if (item === 'Admins') isUser = true;
  });
  return isUser;
};

export const checkAdminsGroup = (roles: Array<String>) => {
  var isAdmin = false;
  roles.forEach((item: String) => {
    if (item === 'Admins') isAdmin = true;
  });
  return isAdmin;
};
