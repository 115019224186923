import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { getAccessToken } from '../../utils/index';
import { Toaster } from '../../components/Toaster';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import SyncIcon from '@material-ui/icons/Sync';
import { VaccineBackdrop } from '../../components/Backdrop';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    header: {
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    uploadBtn: {
      backgroundColor:"white",
      marginLeft:"2rem"
    },
    textField: {
      borderBottom:"1px solid white",
      color:"white",
      fontSize:"0.9rem"
    },
  }),
);



export default function AssignVendors() {
  const [loading,setLoading] =useState(false);
  const [taskInProgress,setTaskInProgress] = useState(false);
  const [data,setData] = useState<{user:string,vendor:string,name:string,vendorToAssign:string,vendorToAssignName:string}[]>([]);
  // const [users,setUsers] = useState<any[]>([]);
  const [vendors,setVendors] = useState<any[]>([]);
  const classes = useStyles();

  const getVendor = (email:string,vendors:any[]) => {
      // console.log(vendors);
      let response = null as any;
      vendors.forEach((vendor)=> {
        // console.log(vendor.email.includes(email));
        
          if(vendor.email.includes(email))
            response = vendor;
      })

      return response;
     
  }

  const fetchData = () => {
    setLoading(true);
    axios.get("https://pv7p3p5mhf.execute-api.us-east-1.amazonaws.com/prod/vendors",{
      headers: {
        'Authorization': getAccessToken(),
      }
    }).then(res=> {
      // console.log(res.data);
      
      // setUsers(res.data.users);
      setVendors(res.data.vendors);
      const vendors = res.data.vendors;
      let tempData:{user:string,vendor:string,name:string,vendorToAssign:string,vendorToAssignName:string}[]=[];
      
      res.data.users.forEach((element:any) => {
        let vendor:{vendor_id:string,vendor_name:string,email:[]} =  getVendor(element.Email,vendors);
        
        tempData.push({
          "user":element.Email,
          "vendor":vendor===null?"not assigned":vendor.vendor_id,
          "name":vendor===null?"not assigned":vendor.vendor_name,
          "vendorToAssign": vendor===null?"not assigned":vendor.vendor_id,
          "vendorToAssignName":vendor===null?"not assigned":vendor.vendor_name,
        })
        // console.log(tempData);
        
      });
      
      setData(tempData);
      setLoading(false);
    }).catch((e)=>{
      Toaster("Something went wrong", 'danger'); 
      setLoading(false);
    })
  }
  
  useEffect(() => {
    
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVendorIdByName = (name:string)=>{
    let response = "not assigned";
    vendors.forEach((vendor)=>{
      if(vendor.vendor_name===name)
        response = vendor.vendor_id
    })
    return response;
  }

  const handleChange  = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,email:string) => {
    let tempData:{user:string,vendor:string,name:string,vendorToAssign:string,vendorToAssignName:string}[]=[...data] ;
    tempData.forEach((item)=> {
      if(item.user===email){
        item.vendorToAssignName=e.target.value;
        item.vendorToAssign = getVendorIdByName(e.target.value);
      }
    })
    setData(tempData);
  }

  const assignHandler = async (item:{user:string,vendor:string,vendorToAssign:string}) => {
    if(item.vendor===item.vendorToAssign)
      return
    setTaskInProgress(true);
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    axios.post("https://pv7p3p5mhf.execute-api.us-east-1.amazonaws.com/prod/vendors/assign",{email:item.user,prevVendor:item.vendor,currentVendor:item.vendorToAssign},
    { 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
    }).then((res)=>{

        // const resObj = JSON.parse(res.data.body);
        // console.log(res);
        Toaster(res.data, 'success'); 
        setTaskInProgress(false);
        fetchData();

    }).catch((e)=>{
      Toaster("Something went wrong", 'danger'); 
      setTaskInProgress(false);
    })
  }
 
 
  const getAssignJsx = (item:{user:string,vendor:string,name:string,vendorToAssign:string,vendorToAssignName:string}) => {
    
    return (
      <span>
        <TextField
        id="outlined-select-currency-native"
        select
        
        value={item.vendorToAssignName}
        onChange={(e)=>handleChange(e,item.user)}
        SelectProps={{
          native: true,
        }}
        variant="standard"
        >
        {vendors.map((option) => (
          <option key={option.vendor_id} value={option.vendor_name}>
            {option.vendor_name}
          </option>
        ))}
        <option key={"not assigned"} value={"not assigned"}>
            {"not assigned"}
          </option>
        </TextField>
        <Button variant="contained" color="primary" style={{marginLeft:"1rem"}} onClick={()=>assignHandler(item)}>Assign</Button>
      </span>
    )
  }

  const getDataView = () => {
    return data.length===0?<TableRow> 
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
    </TableRow>:<>
        {data.map(item=> {
          return( 
            <TableRow key={item.user}>
              <TableCell className="p-3 border-y border-gray-100 text-xs">{item.user}</TableCell>
              <TableCell className="p-3 border-y border-gray-100 text-xs">{item.name} </TableCell>
              <TableCell className="p-3 border-y border-gray-100 text-xs">{item.vendor} </TableCell>
              <TableCell align="center" className="p-3 border-y border-gray-100 text-xs">{getAssignJsx(item)} </TableCell>
            </TableRow>)
          }
        )}
    </>
  }

  return (
    <>
      <VaccineBackdrop open={taskInProgress} ></VaccineBackdrop>
      <div className="min-h-screen max-w-7xl p-6  mx-auto">
        <AppBar position="static" className="py-2"  color="primary">
          <Toolbar className="flex justify-between md:flex-row flex-col">
            <span>
              <Typography variant="h6" noWrap>
              Users
            </Typography>
            <Typography variant="body2" noWrap>
               assign vendor
            </Typography>
            </span>
            <IconButton onClick={fetchData} size="small">
                <SyncIcon htmlColor="white"/>
              </IconButton>
          </Toolbar>
        </AppBar>
        <div className="shadow-md bg-white">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    User Email
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Current Vendor
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Current VendorID
                  </TableCell>
                  <TableCell className={classes.header} align="center" width="40%">
                    Assign Vendor
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
             
              {loading?
                  <TableRow>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell> 
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                  </TableRow>:getDataView()
                }
                
              </TableBody>
              
            </Table>
          </TableContainer>
        </div>
      </div>
      <div>
      </div>
    </>
  );
}
